import React, { Component , useState } from "react";
import { Redirect, Link ,} from "react-router-dom";
import "./style.css";
import InputBase from "@material-ui/core/InputBase";
import axiosIn from "./axiosIn";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import Tooltip from "@material-ui/core/Tooltip";

export default class editBaby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      errors: {},
      regis: [],
      data: [],
      isSuccess: false,
      isExist: true,
      firstname: "",
      lastname: "",
      nickname: "",
      dob: null,
      gender: "",
      image: "",
      day : "",
      month : "",
      year : "",
      realImage: null,

      // weight: "",
      // height: "",
      profileImg: require("./photo/babiezProfile.PNG"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // console.log(props);
  }

  imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({
          profileImg: reader.result,
          image: this.state.profileImg,
        });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    // console.log(this.state.profileImg);
    // console.log(this.state.image);
    // console.log(e.target.files[0]);
    this.setState({ realImage: e.target.files[0], loaded: true });
  };

  // handelImgChange = (e) =>{
  //     const reader= new FileReader();
  //     if(!this.state.image === "./photo/babiezProfile.PNG "){
  //         this.setState = ({
  //             profileImg : this.state.image,
  //         })
  //     }
  // }
  handleChange = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });

  };
  
  handleChangeDate = (dob) => {
    this.setState({
      startDate: dob,
    });
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [
      { value: month },
      { value: _ },
      { value: day },
      { value: _2 },
      { value: year },
    ] = dateTimeFormat.formatToParts(dob);
    this.state.input.dob = `${year}-${month}-${day}`;
    // console.log(`${year}-${month}-${day}`);

  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      firstname: this.state.data.firstname,
    });
    const accessToken = localStorage.getItem("accessToken");
    const header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    axiosIn
      .get(`/baby/${params.index}/`, { headers: header })
      .then((res) => {
        const data = res.data; // get the data array instead of object
        // console.log(data);
        this.setState({ data, loading: false });
        this.setState({ profileImg: res.data.image });
        let dob_str = data.dob.split('-');
        this.state.input.year = dob_str[0];
        this.state.input.month = dob_str[1];
        this.state.input.day = dob_str[2];
        this.setState({ data, loading: false });
        this.setState({ profileImg: data.image });
      })
      .catch((err) => {
        // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      });
    // console.log(params);
  }

  isMale() {
    if (this.state.data.gender == "m") return "checked";
    return null;
  }
  isFemale() {
    if (this.state.data.gender == "f") return "checked";
    return null;
  }

  handleSubmit(event) {
    event.preventDefault();
    const accessToken = localStorage.getItem("accessToken");
    // console.log(accessToken);
    const header = {
        "Content-Type" : "multipart/form-data",
          "Accept" : "application/json",         
          "Authorization" : `Bearer ${accessToken}`
    };
    let dob = this.state.input.year;
    if (this.state.input.month.length <=1){
      dob += "-0"+this.state.input.month;
    }
    else{
      dob += "-"+this.state.input.month;
    }
    if (this.state.input.day.length <=1){
      dob += "-0"+this.state.input.day;
    }
    else{
      dob += "-"+this.state.input.day;
    }
    // console.log(dob);
    const formData = new FormData();
    let data = {
        firstname: this.state.input.firstname,
        lastname: this.state.input.lastname,
        nickname: this.state.input.nickname,
        dob: dob,
        gender: this.state.input.gender,
    };
    // console.log(data);
    if(this.state.realImage != null){
        formData.append("image", this.state.realImage);
    }                                                                                                              
    for (var d in data) {
      if(!(data[d] === undefined)){
        formData.append(d, data[d]);
      }
    }
    // formData.append("data", data);
    // console.log(formData);
    let id = this.state.data.id;

    axiosIn
      .patch(`/baby/${id}/`, formData, { headers: header })
      .then((res) => {
        // console.log(res);
        if (res.status === 202) {
          this.setState({ isSuccess: true }); // after signing up, set the state to true. This will trigger a re-render
        }
      })
      .catch((error) => {
        console.log(error.response);
      });

    if (this.validate()) {
      // console.log(this.state);

      let input = {};
      input["firstname"] = "";
      input["lastname"] = "";
      input["nickname"] = "";
      input["dob"] = "";
      input["gender"] = "";
      input["day"] = "";
      input["month"] = "";
      input["year"] = "";
      // input["weight"] = "";
      // input["height"] = "";
      this.setState({ input: input });

    }
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  render() {
    const { user } = this.state;

    if (this.state.isSuccess) {
        let id = this.state.data.id;

      // redirect to home if signed up
      return <Redirect to={{ pathname: `/User_Home/${id}` }} />;
    }
    if (this.state.loading) {
      return (
        <div>
          <p> Loading... </p>
        </div>
      );
    }

    

    return (
      <div className="bg1-login">
        <br></br>
        <br></br>
        <p className="text-add" style={{fontSize : "4vw"}}>แก้ไขข้อมูลส่วนตัวเด็ก</p>
        <img
          src={this.state.profileImg}
          // key = {this.state.image}
          onChange={this.imageHandler}
          value={this.state.profileImg}
          name="image"
          type="image"
          id="image"
          alt=""
          id="image"
          style={{
            backgroundColor: "#52D3D9",
            maxWidth: "100vw",
            minWidth: "100vw",
            minHeight: "70vw",
            maxHeight: "70vw",
          }}
        ></img>
        <div className="green-bg">
          <br></br>
          <br></br>
          <div className="add-photo">
            <input
              type="file"
              className="upload-Button"
              name="image-upload"
              id="input"
              accept="image/* "
              onChange={this.imageHandler}
            ></input>
            <div className="label">
              <label htmlFor="input" className="image-upload">
                Edit Photo
              </label>
            </div>
          </div>
          <form
            className={editBaby.root}
            noValidate
            autoComplete="on"
            onSubmit={this.handleSubmit}
          >
            <Grid
              container
              spacing={1}
              style={{ marginLeft: "10%", marginTop: "5%", width: "90%" }}
            >
              <Grid item xs={12} sm={2}>
                <span className="span-text">ชื่อ</span>
              </Grid>
              <Grid item xs={12} sm={10}>
                <span className="span-input">
                  <InputBase
                    key={this.state.data.firstname}
                    onChange={this.handleChange}
                    name="firstname"
                    type="text"
                    style={{ fontSize: "1.5em", width: "70%" }}
                    inputProps={{ "aria-label": "naked" }}
                    defaultValue={this.state.data.firstname}
                    className={editBaby.margin}
                    id="firstname"
                  />
                </span>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ marginLeft: "10%", marginTop: "5%", width: "90%" }}
            >
              <Grid item xs={12} sm={2}>
                <span className="span-text">นามสกุล</span>
              </Grid>
              <Grid item xs={12} sm={10}>
                <span className="span-input">
                  <InputBase
                    key={this.state.data.lastname}
                    onChange={this.handleChange}
                    name="lastname"
                    type="text"
                    style={{ fontSize: "1.5em", width: "70%" }}
                    inputProps={{ "aria-label": "naked" }}
                    defaultValue={this.state.data.lastname}
                    className={editBaby.margin}
                    id="lastname"
                  />
                </span>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ marginLeft: "10%", marginTop: "5%", width: "90%" }}
            >
              <Grid item xs={12} sm={2}>
                <span className="span-text">ชื่อเล่น</span>
              </Grid>
              <Grid item xs={12} sm={10}>
                <span className="span-input">
                  <InputBase
                    key={this.state.data.nickname}
                    onChange={this.handleChange}
                    name="nickname"
                    type="text"
                    style={{ fontSize: "1.5em", width: "70%" }}
                    inputProps={{ "aria-label": "naked" }}
                    defaultValue={this.state.data.nickname}
                    className={editBaby.margin}
                    id="nickname"
                  />
                </span>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ marginLeft: "10%", marginTop: "5%", width: "90%" }}
            >
              <Grid item xs={12} sm={2}>
                <span className="span-text">เพศ</span>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Tooltip title="Add" interactive>
                  <Grid
                    container
                    spacing={3}
                    className="grid-gender"
                    style={{ marginTop: "1%" }}
                  >
                    <Grid item xs={6}>
                      <label className={"container"}>
                        <input
                          type="radio"
                          name="gender"
                          value={"m"}
                          checked={this.isMale()}
                          // checked={"checked"}
                          onChange={this.handleChange}
                          id="gender"
                        />
                        ชาย
                        <span className={"checkmark"}></span>
                      </label>
                    </Grid>
                    <Grid item xs={6}>
                      <label
                        className={"container"}
                        style={{ marginLeft: "-30%" }}
                      >
                        <input
                          type="radio"
                          name="gender"
                          value={"f"}
                          checked={this.isFemale()}
                          // checked={"checked"}
                          onChange={this.handleChange}
                          id="gender"
                        />
                        หญิง
                        <span
                          className={"checkmark"}
                          style={{ marginTop: "0%" }}
                        ></span>
                      </label>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>

            {/* <Grid container spacing={1} style={{marginLeft : "9%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={3}>
                            <span className="span-text">น้ำหนัก(g)</span>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <span className="span-input">
                                    <InputBase
                                        key = {this.state.data.wieght}
                                        onChange={this.handleChange} 
                                        name="weight"
                                        type ="number"
                                        style={{ fontSize : '1.5em'  , width : '70%'}}
                                        inputProps={{ 'aria-label': 'naked' }}
                                        defaultValue={this.state.input.weight}
                                        className={editBaby.margin}
                                        id="weight"
                                    />
                                </span>
                            </Grid>
                        </Grid> */}

            {/* <Grid container spacing={1} style={{marginLeft : "8%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={4}>
                            <span className="span-text">ส่วนสูง(cm)</span>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <span className="span-input">
                                    <InputBase
                                        key = {this.state.data.height}
                                        onChange={this.handleChange} 
                                        name="height"
                                        type ="number"
                                        style={{ fontSize : '1.5em'  , width : '70%'}}
                                        inputProps={{ 'aria-label': 'naked' }}
                                        defaultValue={this.state.input.height}
                                        className={editBaby.margin}
                                        id="height"
                                    />
                                </span>
                            </Grid>
                        </Grid> */}

            <Grid
              container
              spacing={1}
              style={{ marginLeft: "10%", marginTop: "5%", width: "90%" }}
            >
              <Grid item xs={12} sm={4}>
                <span className="span-text" style={{ marginLeft: "-3%" }}>
                  วัน/เดือน/ปี เกิด
                </span>
              </Grid>
              <Grid item xs={12} sm={8}>
              <Tooltip title="Add" interactive>
                <Grid container spacing={3} className="grid-gender">
                  <Grid item xs={3}  style={{marginLeft : "10%"}}>
                    <div className={"text-box-login"}>
                      <InputBase
                        key={this.state.data.day}
                        onChange={this.handleChange}
                        name="day"
                        type="number"
                        style={{ fontSize: "1.5em", width: "95%" }}
                        className={editBaby.margin}
                        inputProps={{ "aria-label": "naked" }}
                        defaultValue={this.state.data.day}
                        value={this.state.input.day}
                        id="day"
                      />
                    </div>
                  </Grid>
                  <span style={{marginTop:"5%"}}>/</span>
                  <Grid item xs={3}>
                    <div className={"text-box-login"}>
                      <InputBase
                      key={this.state.data.month}
                      onChange={this.handleChange}
                      name="month"
                      type="number"
                      style={{ fontSize: "1.5em", width: "95%" }}
                      className={editBaby.margin}
                      inputProps={{ "aria-label": "naked" }}
                      defaultValue={this.state.data.month}
                      value={this.state.input.month}
                      id="month"
                    />
                    </div>
                  </Grid>
                  <span style={{marginTop:"5%"}}>/</span>
                  <Grid item xs={4}>
                    <div className={"text-box-login"}>
                      <InputBase
                        key={this.state.data.year}
                        onChange={this.handleChange}
                        name="year"
                        type="number"
                        style={{ fontSize: "1.5em", width: "95%" }}
                        className={editBaby.margin}
                        inputProps={{ "aria-label": "naked" }}
                        value={this.state.input.year}
                        id="year"
                        defaultValue={this.state.data.year}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Tooltip>
              </Grid>
            </Grid>

            <div>
              <span>
                <input
                  type="submit"
                  value="บันทึก"
                  style={{ marginLeft: "25vw" }}
                  className="submit-button"
                />
              </span>
              <span>
                {/* <ฺฺButton href="./User_Home" className="submit-button">ยกเลิก</Button> */}
                <a
                  href="/User_Home"
                  style={{ marginLeft: "10vw" }}
                  className={"cancel-button"}
                >
                  ยกเลิก
                </a>
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}