import React, { Component, useState } from "react";
import { Redirect } from "react-router-dom";
import "./style.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { sizing } from "@material-ui/system";
import user from "./photo/babiezProfile.PNG";
import Container from "@material-ui/core/Container";
import InputBase from "@material-ui/core/InputBase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import axiosIn from "./axiosIn";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Form from "react-bootstrap/Form";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default class regis extends Component {
  constructor() {
    super();
    this.state = {
      dob: null,
      image: [],
      input: {},
      errors: {},
      day : "",
      month : "",
      year : "",
      isSuccess: false,
      isExist: true,
      profileImg: require("./photo/babiezProfile.PNG"),
      startDate: new Date(),
      realImage: null,
      username: "",
      password: "",
      account: {
        firstname: "",
        lastname: "",
        dob: "",
        image: "",
        email: "",
        gender: "",
        // image : ""
      },
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
    this.state.input.dob = `${this.state.input.year}-${this.state.input.month}-${this.state.input.day}`;
    // console.log(this.state.input.dob);
  };
  handleChangeDate = (dob) => {
    this.setState({
      startDate: dob,
    });
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [
      { value: month },
      { value: _ },
      { value: day },
      { value: _2 },
      { value: year },
    ] = dateTimeFormat.formatToParts(dob);
    this.state.input.dob = `${year}-${month}-${day}`;
    // console.log(`${year}-${month}-${day}`);
    // this.state.startDate = dob;
    // var array = dob;
    // document.getElementById("dd").innerHTML = dob;
  };
  imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ profileImg: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    // console.log(this.state.profileImg);
    // console.log(e.target.files[0]);
    this.setState({ realImage: e.target.files[0], loaded: 0 });
  };
  handleSubmit(event) {
    event.preventDefault();
    const header = {
      "Content-Type": "application/json",
    };
    // const formData = new FormData();
    // formData.append("image", this.state.realImage);
    if( this.state.input.day.length <= 1 ){
      this.state.input.day = "0"+this.state.input.day;
    }
    if( this.state.input.month.length <= 1 ){
      this.state.input.month = "0"+this.state.input.month;
    }
    this.state.input.dob = `${this.state.input.year}-${this.state.input.month}-${this.state.input.day}`;
    let data = {
      username: this.state.input.username,
      password: this.state.input.password,
      account: {
        firstname: this.state.input.firstname,
        lastname: this.state.input.lastname,
        email: this.state.input.email,
        gender: this.state.input.gender,
        dob: this.state.input.dob,
        day: this.state.input.day,
        month: this.state.input.month,
        year: this.state.input.year,
        // "image": this.state.profileImg,
      },
    };
    // console.log(data);
    // for (var d in data) {
    //   formData.append(d, data[d]);
    // }
    // formData.append("data", data);

    axiosIn
      .post(`auth/register/`, data, { headers: header })
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        if (res.status === 201) {
          this.setState({ isSuccess: true }); // after signing up, set the state to true. This will trigger a re-render
        }
      })
      .catch((error) => {
        // console.log(error.response.data);
        console.log(error.response)
        alert("Please try again");
      });

    if (this.validate()) {
    //   console.log(this.state);

      let input = {};
      input["username"] = "";
      input["password"] = "";
      input["firstname"] = "";
      input["lastname"] = "";
      input["email"] = "";
      input["image"] = "";
      input["gender"] = "";
      input["confirm_password"] = "";
      input["dob"] = "";
      input["day"] = "";
      input["month"] = "";
      input["year"] = "";
      this.setState({ input: input });
    }
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["username"]) {
      isValid = false;
      errors["username"] = "* Please enter your username.";
    }

    // if (input["username"].isExist === false) {
    //   errors["username"] = "* Please enter your username.";
    // }

    if (!input["firstname"]) {
      isValid = false;
      errors["firstname"] = "* Please enter your firstname.";
    }

    if (!input["lastname"]) {
      isValid = false;
      errors["lastname"] = "* Please enter your surname.";
    }

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "* Please enter your email Address.";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "* Please enter valid email address.";
      }
    }

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "* Please enter your password.";
    }

    if (!input["gender"]) {
      isValid = false;
      errors["gender"] = "* Please enter your gender.";
    }

    if (!input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "* Please enter your confirm password.";
    }

    if (
      typeof input["password"] !== "undefined" &&
      typeof input["confirm_password"] !== "undefined"
    ) {
      if (input["password"] != input["confirm_password"]) {
        isValid = false;
        errors["password"] = "* Passwords don't match.";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }
  render() {
    const day = [];
    const month = [];
    const year = [];
    for (let i=1; i < 32; i += 1) { day.push(i); }
    for (let i=1; i < 13; i += 1) { month.push(i); }
    for (let i=1920; i <2020 ; i += 1) { year.push(i); }

    if (this.state.isSuccess) {
      // redirect to home if signed up
      return <Redirect to={{ pathname: "/login" }} />;
    }
    const { profileImg } = this.state;
    return (
      <div className="bg1-login">
        <br></br>
        <div className="back-icon">
          <Button href="https://mingming15256/DFellow/babiez/c5cb224e434323b02b1345c16f223b03bb0320ef/home.js">
            <ArrowBackIosIcon />
          </Button>
        </div>
        {/* <Grid
                  container
                  justify="center"
                  alignItems="center"
                  item xs={3}
                >
                 
                </Grid> */}
        {/* <AddPhoto/> */}
          <img
            src={profileImg}
            key={this.state.account.realImage}
            onChange={this.imageHandler}
            value={this.state.image}
            name="image"
            type="image"
            id="image"
            alt=""
            id="image"
            style={{
              backgroundColor: "#52D3D9",
              maxHeight: "30vw",
              minHeight: "30vw",
              maxWidth: "30vw",
              minWidth: "30vw",
              borderRadius: "50%",
              marginLeft: "35%",
            }}
          ></img>
        <div className="add-photo">
          <input
            type="file"
            className="upload-Button"
            name="image-upload"
            id="input"
            accept="image/* "
            onChange={this.imageHandler}
          ></input>
          {/* <div className="label">
            <label htmlFor="input" className="image-upload">
              Add Photo
            </label>
          </div> */}
        </div>
        <div className="bg2-login">
          <div className={"word-login-padding"} style={{ paddingTop: "3%" }}>
            <form
              className={regis.root}
              noValidate
              autoComplete="on"
              onSubmit={this.handleSubmit}
            >
              <p className={"text-above-login"}>ชื่อผู้ใช้</p>
              <div className={"text-box-login"}>
                <InputBase
                  onChange={this.handleChange}
                  name="username"
                  type="text"
                  style={{ fontSize: "1.5em", width: "95%" }}
                  inputProps={{ "aria-label": "naked" }}
                  value={this.state.input.username}
                  className={regis.margin}
                  id="username"
                />
              </div>
              <div className="text-danger">{this.state.errors.username}</div>

              <p className={"text-above-login"}>รหัสผ่าน</p>
              <div className={"text-box-login"}>
                <InputBase
                  onChange={this.handleChange}
                  name="password"
                  type="password"
                  style={{ fontSize: "1.5em", width: "95%" }}
                  inputProps={{ "aria-label": "naked" }}
                  value={this.state.input.password}
                  id="password"
                />
              </div>
              <div className="text-danger">{this.state.errors.password}</div>

              <p className={"text-above-login"}>ยืนยันรหัสผ่าน</p>
              <div className={"text-box-login"}>
                <InputBase
                  onChange={this.handleChange}
                  name="confirm_password"
                  type="password"
                  style={{ fontSize: "1.5em", width: "95%" }}
                  inputProps={{ "aria-label": "naked" }}
                  value={this.state.input.confirm_password}
                  id="confirm_password"
                />
              </div>
              <div className="text-danger">
                {this.state.errors.confirm_password}
              </div>

              <p className={"text-above-login"}>อีเมล์</p>
              <div className={"text-box-login"}>
                <InputBase
                  onChange={this.handleChange}
                  name="email"
                  type="email"
                  value={this.state.input.email}
                  id="email"
                  style={{ fontSize: "1.5em", width: "95%" }}
                  inputProps={{ "aria-label": "naked" }}
                />
              </div>
              <div className="text-danger">{this.state.errors.email}</div>

              <p className={"text-above-login"}>ชื่อ</p>
              <div className={"text-box-login"}>
                <InputBase
                  onChange={this.handleChange}
                  name="firstname"
                  type="text"
                  style={{ fontSize: "1.5em", width: "95%" }}
                  className={regis.margin}
                  inputProps={{ "aria-label": "naked" }}
                  value={this.state.input.firstname}
                  id="firstname"
                />
              </div>
              <div className="text-danger">{this.state.errors.firstname}</div>
              <p className={"text-above-login"}>นามสกุล</p>
              <div className={"text-box-login"}>
                <InputBase
                  onChange={this.handleChange}
                  name="lastname"
                  type="text"
                  style={{ fontSize: "1.5em", width: "95%" }}
                  className={regis.margin}
                  inputProps={{ "aria-label": "naked" }}
                  value={this.state.input.lastname}
                  id="lastname"
                />
              </div>
              <div className="text-danger">{this.state.errors.lastname}</div>
              <br></br>
              <p className={"text-above-login"}>วัน/เดือน/ปี เกิด</p>
              <Tooltip title="Add" interactive>
                <Grid container spacing={3} className="grid-gender">
                  <Grid item xs={2}  style={{marginLeft : "10%"}}>
                    <div className={"text-box-login"}>
                      <InputBase
                        onChange={this.handleChange}
                        name="day"
                        type="number"
                        style={{ fontSize: "1.5em", width: "95%" }}
                        className={regis.margin}
                        inputProps={{ "aria-label": "naked" }}
                        value={this.state.input.day}
                        id="day"
                      />
                    </div>
                  </Grid>
                  <span style={{marginTop:"5%"}}>/</span>
                  <Grid item xs={2}>
                    <div className={"text-box-login"}>
                      <InputBase
                      onChange={this.handleChange}
                      name="month"
                      type="number"
                      style={{ fontSize: "1.5em", width: "95%" }}
                      className={regis.margin}
                      inputProps={{ "aria-label": "naked" }}
                      value={this.state.input.month}
                      id="month"
                    />
                    </div>
                  </Grid>
                  <span style={{marginTop:"5%"}}>/</span>
                  <Grid item xs={4}>
                    <div className={"text-box-login"}>
                      <InputBase
                        onChange={this.handleChange}
                        name="year"
                        type="number"
                        style={{ fontSize: "1.5em", width: "95%" }}
                        className={regis.margin}
                        inputProps={{ "aria-label": "naked" }}
                        value={this.state.input.year}
                        id="year"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Tooltip>
              <p className={"text-above-login"}>เพศ</p>
              <Tooltip title="Add" interactive>
                <Grid container spacing={3} className="grid-gender">
                  <Grid item xs={6}>
                    <label className={"container"}>
                      <input
                        type="radio"
                        name="gender"
                        value={"m"}
                        // checked={"checked"}
                        onChange={this.handleChange}
                        id="gender"
                      />
                      ชาย
                      <span className={"checkmark"}></span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <label
                      className={"container"}
                      style={{ marginLeft: "-30%" }}
                    >
                      <input
                        type="radio"
                        name="gender"
                        value={"f"}
                        // checked={"checked"}
                        onChange={this.handleChange}
                        id="gender"
                      />
                      หญิง
                      <span
                        className={"checkmark"}
                        style={{ marginTop: "0%" }}
                      ></span>
                    </label>
                  </Grid>
                </Grid>
              </Tooltip>
              <div className="text-danger">{this.state.errors.gender}</div>
              <br></br>

              <input
                type="submit"
                value="ลงทะเบียน"
                style={{ marginLeft: "12vw" }}
                className="login-button"
              />
            </form>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}