import React, { Component, useState } from 'react';
import { Redirect } from 'react-router-dom'
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { sizing } from '@material-ui/system';
import user from './photo/babiezProfile.PNG';
import Container from '@material-ui/core/Container';
import InputBase from '@material-ui/core/InputBase';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Form from 'react-bootstrap/Form'
import axiosIn from './axiosIn'




export default class regis extends Component {
  constructor() {
    super()
    this.state = {
      input: {},
      errors: {},
      regis: [],
      data: [],
      date: [],
      day: "",
      month: "",
      year: "",
      startDate: new Date(),
      isSuccess: false,
      isExist: true,
      profileImg: require('./photo/babiezProfile.PNG'),
      account: {
        firstname: "",
        lastname: "",
        dob: null,
        image: "",
        email: "",
        gender: "",
        realImage: null
      }

    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.logout = this.logout.bind(this);
  }

  logout = (e) => {
    localStorage.clear();
    window.location.href = '/';
  }

  imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({
          profileImg: reader.result,
          image: this.state.profileImg,
        });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    console.log(this.state.profileImg);
    console.log(this.state.image);
    console.log(e.target.files[0]);
    this.setState({ realImage: e.target.files[0], loaded: true });
  };

  handleChange = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });



  }

  componentDidMount() {
    this.setState({
      firstname: this.state.data.firstname,
    });
    // this.state.input.year= `${date[0]}`;
    // this.state.input.month = `${date[1]}`;
    // this.state.input.day = `${date[2]}`;
    const accessToken = localStorage.getItem('accessToken');
    const header = {
      "Content-Type": "multipart/form-data",
      "Accept": "application/json",
      "Authorization": `Bearer ${accessToken}`
    }
    axiosIn.get('auth/edit_account/', { headers: header })
      .then(res => {
        const data = res.data[0]; // get the data array instead of object
        console.log(data);
        // this.setState({ data, loading: false });
        if (data.dob != null) {
          let dob_str = data.dob.split('-');
          this.state.input.year = dob_str[0];
          this.state.input.month = dob_str[1];
          this.state.input.day = dob_str[2];
        }
        this.setState({ data, loading: false });
        this.setState({ profileImg: data.image });


      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      })

  }

  isMale() {
    if (this.state.data.gender == 'm')
      return "checked";
    return null;
  }
  isFemale() {
    if (this.state.data.gender == 'f')
      return "checked";
    return null;
  }
  handleSubmit(event) {
    event.preventDefault();
    let errorsUser = "";
    const accessToken = localStorage.getItem('accessToken');
    console.log(accessToken);
    const header = {
      "Content-Type": "multipart/form-data",
      "Accept": "application/json",
      "Authorization": `Bearer ${accessToken}`


    }
    let dob = this.state.input.year;
    if (this.state.input.month.length <= 1) {
      dob += "-0" + this.state.input.month;
    }
    else {
      dob += "-" + this.state.input.month;
    }
    if (this.state.input.day.length <= 1) {
      dob += "-0" + this.state.input.day;
    }
    else {
      dob += "-" + this.state.input.day;
    }
    console.log(dob);
    const formData = new FormData();
    let data = {
      "firstname": this.state.input.firstname,
      "lastname": this.state.input.lastname,
      "email": this.state.input.email,
      "gender": this.state.input.gender,
      "dob": dob,
    };

    if (this.state.realImage != null) {
      formData.append("image", this.state.realImage);
    }
    for (var d in data) {
      if (!(data[d] === undefined)) {
        formData.append(d, data[d]);
      }
    }
    // formData.append('data', data);
    console.log(data)

    // axios.get(`https://babiez.dev.itrashyou.com/api/v1/auth/edit_account/`,  data , {headers:header})
    // .then(res => {
    //   const regis = res.data;
    //   this.setState({ regis });
    // })

    axiosIn.patch(`auth/edit_account/`, formData, { headers: header })
      .then(res => {
        console.log(res);
        console.log(res.data);
        if (res.status === 202) {
          this.setState({ isSuccess: true }); // after signing up, set the state to true. This will trigger a re-render
        }
      })
      .catch(error => {
        console.log(error.response);
      })





    if (this.validate()) {
      console.log(this.state);

      let input = {};
      input["firstname"] = "";
      input["lastname"] = "";
      input["email"] = "";
      input["gender"] = "";
      input["dob"] = "";
      input["confirm_password"] = "";
      input["image"] = "";
      input["day"] = "";
      input["month"] = "";
      input["year"] = "";
      this.setState({ input: input });

    }
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;


    this.setState({
      errors: errors
    });

    return isValid;
  }
  render() {
    if (this.state.isSuccess) {
      // redirect to home if signed up
      return <Redirect to={{ pathname: "/User_Home" }} />;
    }
    if (this.state.loading) {
      return (
        <div>
          <p> Loading... </p>
        </div>
      )
    }

    const { profileImg } = this.state
    return (
      <div className="bg1-login">
        <br></br>
        <div className="back-icon">
          <Button href="./User_Home">
            <ArrowBackIosIcon />
          </Button>
        </div>

        <img src={profileImg}
          src={this.state.profileImg}
          onChange={this.imageHandler}
          value={this.state.image}
          name="image"
          type="image"
          id="image"
          alt=""
          id="image"
          style={{ backgroundColor: '#52D3D9', maxHeight: '30vw', minHeight: '30vw', maxWidth: '30vw', minWidth: '30vw', borderRadius: '50%', marginLeft: "35%" }}                      >
        </img>
        <div className="add-photo">
          <input
            type="file"
            className="upload-Button"
            name="image-upload"
            id="input"
            accept="image/* "
            onChange={this.imageHandler}
          ></input>
          <div className="label">
            <label htmlFor="input" className="image-upload">
              Add Photo
                      </label>
          </div>
        </div>
        <br></br><br></br>
        <div className="bg2-login">
          <div className={"word-login-padding"} style={{ paddingTop: "3%" }}>
            <form className={regis.root} noValidate autoComplete="on" onSubmit={this.handleSubmit}>
              <p className={"text-above-login"}>ชื่อ</p>
              <div className={"text-box-login"}>
                <InputBase
                  key={this.state.data.firstname}
                  onChange={this.handleChange}
                  name="firstname"
                  type="text"
                  style={{ fontSize: '1.5em', width: "95%" }}
                  className={regis.margin}
                  inputProps={{ 'aria-label': 'naked' }}
                  defaultValue={this.state.data.firstname}
                  // value={this.state.regis.input.map(regis => <li>{regis.firstname}</li>)}
                  id="firstname"
                />
              </div>
              <div className="text-danger">{this.state.errors.firstname}</div>
              <p className={"text-above-login"}>นามสกุล</p>
              <div className={"text-box-login"}>
                <InputBase
                  key={this.state.data.lastname}
                  onChange={this.handleChange}
                  name="lastname"
                  type="text"
                  style={{ fontSize: '1.5em', width: "95%" }}
                  className={regis.margin}
                  inputProps={{ 'aria-label': 'naked' }}
                  placeholder={"ddd"}
                  defaultValue={this.state.data.lastname}
                  id="lastname"
                />
              </div>
              <div className="text-danger">{this.state.errors.lastname}</div>

              <p className={"text-above-login"}>วัน/เดือน/ปี เกิด</p>
              <Tooltip title="Add" interactive>
                <Grid container spacing={3} className="grid-gender">
                  <Grid item xs={2} style={{ marginLeft: "10%" }}>
                    <div className={"text-box-login"}>
                      <InputBase
                        key={this.state.data.day}
                        onChange={this.handleChange}
                        name="day"
                        type="number"
                        style={{ fontSize: "1.5em", width: "95%" }}
                        className={regis.margin}
                        inputProps={{ "aria-label": "naked" }}
                        defaultValue={this.state.data.day}
                        value={this.state.input.day}
                        id="day"
                      />
                    </div>
                  </Grid>
                  <span style={{ marginTop: "5%" }}>/</span>
                  <Grid item xs={2}>
                    <div className={"text-box-login"}>
                      <InputBase
                        key={this.state.data.month}
                        onChange={this.handleChange}
                        name="month"
                        type="number"
                        style={{ fontSize: "1.5em", width: "95%" }}
                        className={regis.margin}
                        inputProps={{ "aria-label": "naked" }}
                        defaultValue={this.state.data.month}
                        value={this.state.input.month}
                        id="month"
                      />
                    </div>
                  </Grid>
                  <span style={{ marginTop: "5%" }}>/</span>
                  <Grid item xs={4}>
                    <div className={"text-box-login"}>
                      <InputBase
                        key={this.state.data.year}
                        onChange={this.handleChange}
                        name="year"
                        type="number"
                        style={{ fontSize: "1.5em", width: "95%" }}
                        className={regis.margin}
                        inputProps={{ "aria-label": "naked" }}
                        value={this.state.input.year}
                        id="year"
                        defaultValue={this.state.data.year}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Tooltip>
              <p className={"text-above-login"}>เพศ</p>
              <Tooltip title="Add" interactive>
                <Grid container spacing={3} className="grid-gender">
                  <Grid item xs={6}>
                    <label className={"container"} >
                      <input
                        type="radio"
                        name="gender"
                        value={"m"}
                        checked={this.isMale()}
                        onChange={this.handleChange}
                        id="gender"
                      />ชาย
                                <span className={"checkmark"}></span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <label className={"container"} style={{ marginLeft: "-30%" }}>
                      <input
                        type="radio"
                        name="gender"
                        value={"f"}
                        checked={this.isFemale()}
                        onChange={this.handleChange}
                        id="gender"
                      />หญิง
                                <span className={"checkmark"} style={{ marginTop: "0%" }}></span>
                    </label>
                  </Grid>
                </Grid>
              </Tooltip>
              <div className="text-danger">{this.state.errors.gender}</div>

              <p className={"text-above-login"}>อีเมล์</p>
              <div className={"text-box-login"}>
                <InputBase
                  key={this.state.data.email}
                  onChange={this.handleChange}
                  name="email"
                  type="email"
                  value={this.state.input.email}
                  id="email"
                  style={{ fontSize: '1.5em', width: "95%" }}
                  inputProps={{ 'aria-label': 'naked' }}
                  defaultValue={this.state.data.email}
                />
              </div>
              <div className="text-danger">{this.state.errors.email}</div>

              <br></br>

              <input type="submit" value="แก้ไขข้อมูล" style={{ marginLeft: "12vw" }} className="login-button" />


              <Button variant="danger" style={{ marginLeft: "20vw" }}
                onClick={this.logout}
              >logout</Button>

            </form>
            <br></br><br></br>
          </div>
        </div>
      </div>


    )
  }
}