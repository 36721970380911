import React , {Component} from 'react';
import { Redirect , Link } from 'react-router-dom'
import './style.css';
import InputBase from '@material-ui/core/InputBase';
import axiosIn from './axiosIn';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import Tooltip from '@material-ui/core/Tooltip';







export default class addBaby extends Component{
    constructor() {
        super()
        this.state = {
            input: {},
            errors: {},
            startDate: new Date(),
            isSuccess: false,
            isExist : true,
            firstname : "",
            lastname: "",
            nickname: "",
            dob: null,
            gender : "",
            weight: "",
            height: "",
            image: "",
            day : "",
            month : "",
            year : "",
            profileImg : require('./photo/babiezProfile.PNG'),
            realImage: null
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    imageHandler =  (e) =>{
        const reader= new FileReader();
        reader.onload = () =>{
          if(reader.readyState === 2){
            this.setState({profileImg:reader.result})
          }
        }
        reader.readAsDataURL(e.target.files[0])
        // console.log(this.state.profileImg);
        // console.log(e.target.files[0]);
        this.setState({realImage:e.target.files[0],
            loaded:0,
        })
      }
    handleChange = (event) => {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        this.setState({
          input,
        })
        this.state.input.dob = `${this.state.input.year}-${this.state.input.month}-${this.state.input.day}`;
    }
    handleChangeDate = dob => {
        this.setState({
            startDate: dob
          });
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const [{ value: month },{value:_},{ value: day },{value:_2},{ value: year }] = dateTimeFormat.formatToParts(dob);
        this.state.input.dob = `${year}-${month}-${day}`
        // var array = dob;
        // document.getElementById("dd").innerHTML = dob;
      };
    handleSubmit(event) {
        event.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        // console.log(accessToken);
        const header = {
          "Content-Type" : "multipart/form-data",
          "Accept" : "application/json",         
          "Authorization" : `Bearer ${accessToken}`


        }
        const formData = new FormData();
        if(this.state.realImage != null){
            formData.append('image', this.state.realImage);
        }
        let data = {
            "firstname" : this.state.input.firstname,
            "lastname": this.state.input.lastname,
            "nickname": this.state.input.nickname,
            "dob": this.state.input.dob,
            "gender": this.state.input.gender,
            "image": this.state.profileImg,
            "day": this.state.input.day,
            "month": this.state.input.month,
            "year": this.state.input.year,
            // "weight": this.state.input.weight,
            // "height": this.state.input.height,
      };
      for(var d in data){
          if(data[d] != undefined ){
            formData.append(d, data[d]);
          }
      }
    //   formData.append('data', data);
    //   console.log(data)
    
        axiosIn.post(`/baby/`,  formData , {headers:header})
          .then(res => {
            // console.log(res);
            // console.log(res.data);
            if (res.status === 201) {
              this.setState({ isSuccess: true }); // after signing up, set the state to true. This will trigger a re-render
            }
          })
          .catch(error => {
            console.log(error.response.data);
            console.error(error); 
          })
  
          
        
      
        if(this.validate()){
            // console.log(this.state);
      
            let input = {};
            input["firstname"] = "";
            input["lastname"] = "";
            input["nickname"] = "";
            input["dob"] = "";
            input["gender"] = "";
            input["weight"] = "";
            input["height"] = "";
            input["image"] = "";
            input["day"] = "";
            input["month"] = "";
            input["year"] = "";
            this.setState({input:input});
      
        }
      }
      validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;
  
        if (!input["firstname"]) {
          isValid = false;
          errors["firstname"] = "* Please enter your firstname.";
        }
  
        if (!input["lastname"]) {
          isValid = false;
          errors["lastname"] = "* Please enter your surname.";
        }
    
        if (!input["nickname"]) {
          isValid = false;
          errors["nickname"] = "* Please enter your nickname.";
        }

        // if (!input["weight"]) {
        //     isValid = false;
        //     errors["weight"] = "* Please enter your weight.";
        // }

        // if (!input["height"]) {
        //   isValid = false;
        //   errors["height"] = "* Please enter your height.";
        // }

        this.setState({
          errors: errors
        });
    
        return isValid;
    }
    
    render() {
        const day = [];
        const month = [];
        const year = [];
        for (let i=1; i < 32; i += 1) { day.push(i); }
        for (let i=1; i < 13; i += 1) { month.push(i); }
        for (let i=1920; i <2020 ; i += 1) { year.push(i); }
        if (this.state.isSuccess) {
            // redirect to home if signed up
            return <Redirect to = {{ pathname: "/User_Home" }} />;
          }
        const {profileImg} = this.state 
        return(
            <div className="bg1-login">
                <br></br><br></br>
                <p className="text-add" >เพิ่มข้อมูลส่วนตัวเด็ก</p>
                <img src={profileImg} 
                    onChange={this.imageHandler}                          
                    value={this.state.image}
                    name="image"
                    type ="image"
                    id="image"
                    alt="" 
                    id="image" 
                    style={{ backgroundColor: '#52D3D9', maxWidth:'100vw', minWidth:'100vw', minHeight:'70vw',maxHeight:'70vw'}}
                    >
                  </img>
                <div className="green-bg">
                    <br></br><br></br>
                    <div className="add-photo">
                        <input type="file" className="upload-Button" name="image-upload" id="input" accept="image/* " onChange={this.imageHandler} required></input>
                        <div className="label">
                            <label htmlFor="input" className="image-upload">Add Photo</label>
                        </div>
                    </div>
                    <form className={addBaby.root} noValidate autoComplete="on" onSubmit={this.handleSubmit}>
                    <Grid container spacing={1} style={{marginLeft : "10%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={2}>
                            <span className="span-text" >ชื่อ</span>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <span className="span-input">
                                    <InputBase
                                        onChange={this.handleChange} 
                                        name="firstname"
                                        type ="text"
                                        style={{ fontSize : '1.5em'  , width : '70%'}}
                                        inputProps={{ 'aria-label': 'naked' }}
                                        value={this.state.input.firstname}
                                        className={addBaby.margin}
                                        id="firstname"
                                    />
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{marginLeft : "10%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={2}>
                            <span className="span-text">นามสกุล</span>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <span className="span-input">
                                    <InputBase
                                        onChange={this.handleChange} 
                                        name="lastname"
                                        type ="text"
                                        style={{ fontSize : '1.5em'  , width : '70%'}}
                                        inputProps={{ 'aria-label': 'naked' }}
                                        value={this.state.input.lastname}
                                        className={addBaby.margin}
                                        id="lastname"
                                    />
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{marginLeft : "10%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={2}>
                            <span className="span-text">ชื่อเล่น</span>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <span className="span-input">
                                    <InputBase
                                        onChange={this.handleChange} 
                                        name="nickname"
                                        type ="text"
                                        style={{ fontSize : '1.5em'  , width : '70%'}}
                                        inputProps={{ 'aria-label': 'naked' }}
                                        value={this.state.input.nickname}
                                        className={addBaby.margin}
                                        id="nickname"
                                    />
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{marginLeft : "10%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={2}>
                            <span className="span-text">เพศ</span>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <Tooltip title="Add" interactive>
                                    <Grid container spacing={3} className="grid-gender" style={{marginTop:"1%"}}>
                                        <Grid item xs={6}>
                                            <label className={"container"} >
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    value={"m"}
                                                    // checked={"checked"}
                                                    onChange={this.handleChange}
                                                    id="gender"
                                                />ชาย
                                                <span className={"checkmark"}></span>
                                            </label>        
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label className={"container"} style={{marginLeft:"-30%"}}>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    value={"f"}
                                                // checked={"checked"}
                                                    onChange={this.handleChange}
                                                    id="gender"
                                                />หญิง
                                                <span className={"checkmark"} style={{marginTop: "0%"}}></span>
                                            </label>        
                                        </Grid>
                                    </Grid>                      
                                </Tooltip>
                            </Grid>
                        </Grid>
{/* 
                        <Grid container spacing={1} style={{marginLeft : "9%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={3}>
                            <span className="span-text">น้ำหนัก(g)</span>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <span className="span-input">
                                    <InputBase
                                        onChange={this.handleChange} 
                                        name="weight"
                                        type ="number"
                                        style={{ fontSize : '1.5em'  , width : '70%'}}
                                        inputProps={{ 'aria-label': 'naked' }}
                                        value={this.state.input.weight}
                                        className={addBaby.margin}
                                        id="weight"
                                    />
                                </span>
                            </Grid>
                        </Grid> */}
{/* 
                        <Grid container spacing={1} style={{marginLeft : "8%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={4}>
                            <span className="span-text">ส่วนสูง(cm)</span>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <span className="span-input">
                                    <InputBase
                                        onChange={this.handleChange} 
                                        name="height"
                                        type ="number"
                                        style={{ fontSize : '1.5em'  , width : '70%'}}
                                        inputProps={{ 'aria-label': 'naked' }}
                                        value={this.state.input.height}
                                        className={addBaby.margin}
                                        id="height"
                                    />
                                </span>
                            </Grid>
                        </Grid>
                         */}
                        <Grid container spacing={1} style={{marginLeft : "10%" , marginTop : "5%" , width : "90%"}}>
                            <Grid item xs={12} sm={4}>
                            <span className="span-text" style={{marginLeft : "-3%" }}>วัน/เดือน/ปี เกิด</span>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                            <Tooltip title="Add" interactive>
                                <Grid container spacing={3} className="grid-gender">
                                <Grid item xs={3}  style={{marginLeft : "10%"}}>
                                    <div className={"text-box-login"}>
                                    <InputBase
                                        onChange={this.handleChange}
                                        name="day"
                                        type="number"
                                        style={{ fontSize: "1.5em", width: "95%" }}
                                        className={addBaby.margin}
                                        inputProps={{ "aria-label": "naked" }}
                                        value={this.state.input.day}
                                        id="day"
                                    />
                                    </div>
                                </Grid>
                                <span style={{marginTop:"5%"}}>/</span>
                                <Grid item xs={3}>
                                    <div className={"text-box-login"}>
                                    <InputBase
                                    onChange={this.handleChange}
                                    name="month"
                                    type="number"
                                    style={{ fontSize: "1.5em", width: "95%" }}
                                    className={addBaby.margin}
                                    inputProps={{ "aria-label": "naked" }}
                                    value={this.state.input.month}
                                    id="month"
                                    />
                                    </div>
                                </Grid>
                                <span style={{marginTop:"5%"}}>/</span>
                                <Grid item xs={4}>
                                    <div className={"text-box-login"}>
                                    <InputBase
                                        onChange={this.handleChange}
                                        name="year"
                                        type="number"
                                        style={{ fontSize: "1.5em", width: "95%" }}
                                        className={addBaby.margin}
                                        inputProps={{ "aria-label": "naked" }}
                                        value={this.state.input.year}
                                        id="year"
                                    />
                                    </div>
                                </Grid>
                                </Grid>
                            </Tooltip>
                            </Grid>
                        </Grid>

                        <div>
                            <span>
                                <input type="submit" value="บันทึก" style={{marginLeft :"25vw" }} className="submit-button" />
                            </span>
                            <span>
                                {/* <ฺฺButton href="./User_Home" className="submit-button">ยกเลิก</Button> */}
                                <a href="/User_Home" style={{marginLeft :"10vw" }} className={"cancel-button"}>ยกเลิก</a>
                            </span>
                        </div>

                    
                    </form>
                </div>
        
            </div>

        );
      }
     
      
      
     }
