import React from 'react';
import axios from 'axios';
import { Redirect , Link } from 'react-router-dom'
import {makeStyles, Tabs, Card, Avatar,Tooltip, Fab, Box, Typography } from '@material-ui/core';
import imgpic from '../img/babe_1.jpg'; 
import AddIcon from '@material-ui/icons/Add';
import axiosIn from '../axiosIn';


class babycard extends React.Component {

    constructor() {
        super()
        this.state = {
            datababy : [],
        };
    }

    componentDidMount() {
        // console.log("Pass")
        const accessToken = localStorage.getItem('accessToken');
        const header = {
          "Content-Type" : "application/json",
          "Accept" : "application/json", 
          "Authorization" : `Bearer ${accessToken}`
        }
        axiosIn.get('/baby/' , {headers:header}).then(res => {            
            this.setState({ datababy : res.data });
            // console.log(this.state.datababy);
        }).catch(err => { // log request error and prevent access to undefined state
            this.setState({ loading: false, error: true });
            console.error(err); 
          })

    }

    // renderRedirect = () => {
    //     if (this.state.index !== 0 && this.state.index !== '') {
    //       return (
    //         <Redirect 
    //           to={`/editBaby/${this.state.index}`}
    //         />
    //       );
    //     }
    //   };

    render() { 
        // const id = this.state.match.params.id;
        // console.log(id)
        return(
            <div>
            <Tabs 
                orientation="horizontal"
                variant="scrollable"
            >
                {this.state.datababy.map((babyDetail, index) => {
                    return (
                        <Link to ={`/User_Home/${babyDetail.id}`}
                            style={{
                                padding: '5vw '
                            }}
                        >
                            <Card style={{ height: '38vh', width: '38vh', justifyContent: 'center', }}>                                       
                                <Avatar
                                    key={index} 
                                    variant="square"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    src={babyDetail.image}
                                    alt="baby card"                                    
                                /> 
                                <Box style={{ 
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)',                        
                                    width: '38vh',
                                    overflow: 'hidden',
                                    color: 'black',
                                    position: 'absolute',
                                    bottom: '5vw',
                                }}>
                                    <Box display="flex" justifyContent="space-between" p={1}>
                                        <Box key={index} display="inline">
                                            {babyDetail.firstname} {babyDetail.lastname}
                                        </Box>
                                        <Box key={index} display="inline">
                                            {babyDetail.nickname}
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                            
                        </Link> 
                
                    )
                })}
                <div 
                    style={{
                        display: 'flex',
                        height: '38vh',
                        width: '38vh',
                        padding: '5vw',
                        justifyContent: 'center',
                        alignItems: 'center',                
                    }}
                >
                        <Tooltip title="Add" aria-label="add">
                        <Fab style={{ backgroundColor: '#52D3D9', color: '#FFFFFF' }}>
                            <a href="addBaby/">
                                <AddIcon/>
                            </a>
                        </Fab>
                        
                    </Tooltip>
                </div>
            </Tabs>
            </div>

        
        );
    }
}

export default babycard;
