import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, } from 'recharts';
import axios from 'axios';
import axiosIn from '../axiosIn';


export default class Example extends PureComponent {
  //static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            BID: '',
        };
    }
    

   componentWillReceiveProps() {
        // console.log(this.props.bid);
        //try to move get to new function
        const accessToken = localStorage.getItem('accessToken');
        const header = {
          "Content-Type" : "application/json",
          "Accept" : "application/json", 
          "Authorization" : `Bearer ${accessToken}`
        }

        let types = this.props.type;
        let num = this.props.num;
        let babyID = this.props.bid;
        // console.log(num);
        axiosIn.get(`/${this.props.graph}/?types=${types}&num=${num}&baby=${babyID}` , {headers:header}).then(res => { 
            console.log(res.data);           
            this.setState({ data : res.data });
            //console.log(this.state.data);
        }).catch(err => { 
            this.setState({ loading: false, error: true });
            console.error(err); 
          })
    }

    // componentDidUpdate(){
    //     console.log("we work");
    // }

    render() {
        // console.log(this.state.BID);
        // console.log(this.state.data);
        return (
        <LineChart
            width={350}
            height={300}
            data={this.state.data}        
            margin={{
            top: 10, right: 10, left: 10, bottom: 10,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp__date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={this.props.dkey} name={this.props.name} stroke={this.props.color} activeDot={{ r: 8 }} />

        </LineChart>
        );
    }
}
