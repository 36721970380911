import React from 'react';
import './App.css';
import {Route , Link} from 'react-router-dom'
import DataButton from './component/home_select';
import logo from './img/icon_Babiez_edit.png';
import { AppBar, Button, Box, withStyles, Grid } from '@material-ui/core';
// import TestModal from './component/modal';

const MyButton = withStyles((theme) => ({
    root: {
        //color: theme.palette.getContrastText('#ffffff'),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor:'#ffffff',
        },
        boxShadow: '0 2px 4px 1px rgba(0, 0, 0, .3)',
    },
}))(Button);
  
const Home = () => {
    const accessToken = localStorage.getItem('accessToken');
    console.log(accessToken);
    if(accessToken === null){
        return(
            <div style={{ backgroundColor: '#ffee99', minHeight: '100vh' }}>
                <AppBar position="static" className="TopNav"  elevation={0} >
                   <div style={{ width: '100vw'}}>                    
                        <Box display="flex" justifyContent="space-between" >
                            <Box p={1}>
                                                         
                            </Box>
                            <Box p={1} alignSelf="center">
                                <MyButton href="./login">Login</MyButton>
                            </Box>
                        </Box>                   
                    </div>
                </AppBar>
                
                <Box m={1} p={1}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Box display="flex" justifyContent="center">
                            <img src={logo} alt="logo" style={{ width: '50vw', height: '50vw' }} />
                        </Box>
                        <DataButton />
                        {/* <TestModal />               */}
                    </Grid>
                </Box>
            </div>
        );
    }
    return(
        <div style={{ backgroundColor: '#ffee99', minHeight: '100vh' }}>
            <AppBar position="static" className="TopNav"  elevation={0} >
               <div style={{ width: '100vw'}}>                    
                    <Box display="flex" justifyContent="space-between" >
                        <Box p={1}>
                                                     
                        </Box>
                        <Box p={1} alignSelf="center">
                            <MyButton href="./User_Home">Account</MyButton>
                        </Box>
                    </Box>                   
                </div>
            </AppBar>
            
            <Box m={1} p={1}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Box display="flex" justifyContent="center">
                        <img src={logo} alt="logo" style={{ width: '50vw', height: '50vw' }} />
                    </Box>
                    <DataButton />
                    {/* <TestModal />               */}
                </Grid>
            </Box>
        </div>
    );
    
}

export default Home;
