import React, { component, Component } from "react";
import { Redirect, Link } from "react-router-dom";
import "./style.css";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import logo from "./photo/babiez2.png";
// import { FormControl } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import { borders } from "@material-ui/system";
import InputBase from "@material-ui/core/InputBase";
import axiosIn from "./axiosIn";
// import InputLabel from '@material-ui/core/InputLabel';

console.log(logo);

export default class login extends Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      username: "",
      password: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    let errorsUser = "";
    const header = {
      "Content-Type": "application/json",
    };
    let data = {
      username: this.state.input.username,
      password: this.state.input.password,
    };
    console.log(data);

    axiosIn
      .post(`/auth/token/`, data, { headers: header })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.status === 200) {
          this.setState({ isSuccess: true }); // after signing up, set the state to true. This will trigger a re-render
          localStorage.setItem("accessToken", res.data.access);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });

    if (this.validate()) {
      console.log(this.state);

      let input = {};
      input["username"] = "";
      input["password"] = "";
      this.setState({ input: input });
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["username"]) {
      isValid = false;
      errors["username"] = "* Please enter your username.";
    }

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "* Please enter your password.";
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  render() {
    if (this.state.isSuccess) {
      // redirect to home if signed up
      // return <Redirect to={{ pathname: "/User_Home" }} />;
      return window.location.href = '/User_Home';
    }
    return (
      <div className="bg1-login">
        <br></br>
        <div className="bg2-login">
          <Image className={"logo-img"} src={logo} />
          <div className={"word-login-padding"} style={{ paddingTop: "10%" }}>
            <form
              className={login.root}
              onSubmit={this.handleSubmit}
              noValidate
              autoComplete="on"
            >
              <p className={"text-above-login"}>ชื่อผู้ใช้</p>
              <div className={"text-box-login"}>
                <InputBase
                  onChange={this.handleChange}
                  name="username"
                  type="text"
                  style={{ fontSize: "1.5em" }}
                  className={login.margin}
                  inputProps={{ "aria-label": "naked" }}
                  value={this.state.input.username}
                />
              </div>
              <div className="text-danger">{this.state.errors.username}</div>

              <p className={"text-above-login"}>รหัสผ่าน</p>
              <div className={"text-box-login"}>
                <InputBase
                  onChange={this.handleChange}
                  name="password"
                  type="password"
                  style={{ fontSize: "1.5em", width: "95%" }}
                  inputProps={{ "aria-label": "naked" }}
                  value={this.state.input.password}
                  id="password"
                />
              </div>
              <div className="text-danger">{this.state.errors.password}</div>

              <input
                type="submit"
                value="เข้าสู่ระบบ"
                style={{ marginLeft: "12vw" }}
                className="login-button"
              />
            </form>
          </div>

          <div className={"forget-margin"}>
            <span>ยังไม่มีบัญชี?</span>
            <Button href="./regis" color="primary">
              สมัครบัญชี
            </Button>
          </div>

          {/* <Form className={"word-login-padding"}>
                <Form.Group controlId="formBasicEmail">
                <Form.Label className={"text-above-login"}>ชื่อผู้ใช้</Form.Label>
                <br></br>

                <Form.Control onChange={this.handleChange} name="username" className={"text-box-login"} type="text" />
                </Form.Group>
                <br></br>

                <Form.Group controlId="formBasicPassword">
                <Form.Label className={"text-above-login"}>รหัสผ่าน</Form.Label>
                <br></br>

                <Form.Control onChange={this.handleChange} name="password" className={"text-box-login"} type="password" />
                </Form.Group>
        
                <Button className={"login-button"} type="submit">
                  {'ลงชื่อเข้าใช้'}
                </Button>
                </Form>    */}

          {/* <ButtonGroup className={"forget-margin"} aria-label="Basic example">
                  <span>ยังไม่มีบัญชี? </span>
                  <Button className={"forget-link"} href="/" variant="link">{'สมัครบัญชี'}</Button>  
                  <span>{" | "}</span>             
                  <Button className={"forget-link"} href="/" variant="link">{'ลืมรหัสผ่าน'}</Button>     
                </ButtonGroup>    

                <h1>{this.state.username} ddd</h1>    */}
        </div>
      </div>
    );
  }
}

{
  /* <div>
    <Desktop>Desktop or laptop</Desktop>
    <Tablet>Tablet</Tablet>
    <Mobile>Mobile</Mobile>
    <Default>Not mobile (desktop or laptop or tablet)</Default>
  </div> */
}