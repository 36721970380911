import React, { useState, Component, useEffect } from 'react';
import { Button, Typography, Box, InputLabel, Fade, Modal, Backdrop } from '@material-ui/core';
import { Select, MenuItem, TextField, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Radio, FormControl } from '@material-ui/core';
import axiosIn from '../axiosIn';

class AddMilk extends React.Component {  

    constructor(props) {
        super(props);
        this.state = {
            input: {},
            id: (null),
            type: (null),
            volume: (null),   
            closeModal: true,              
        };

        // this.handleSubmitEvent = this.handleSubmitEvent.bind(this);
    }

    handleChange = (e) => {
        let name = e.target.name;
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [name]: value });
           
    }

    handleSubmitEvent = () => {
        // console.log("summit work");        
        const accessToken = localStorage.getItem('accessToken');
        const header = {
          "Content-Type" : "application/json",
          "Accept" : "application/json", 
          "Authorization" : `Bearer ${accessToken}`
        }

        let data = {
            "baby" : this.state.id,
            "types" : this.state.type,
            "volume" : this.state.volume,
        }
        // console.log(data);
        
        axiosIn.post(`milk/`,  data , {headers:header})
        .then(res => {
            // console.log(res);
            // console.log(res.data);
            // alert("uploade");
            if (res.status === 201) {
                this.setState({ isSuccess: true }); // after signing up, set the state to true. This will trigger a re-render
            }
        })
        .catch(error => {
            console.log(error);
            // alert(error.response.data.username);
        })
        
        // this.setState({ closeModal: this.props.isShow });
    }

    componentWillUnmount() {
        this.handleSubmitEvent();
        // ?console.log("i'm leave");
    }
    componentDidUpdate() {
        // console.log(this.state);
    }

    render() {    
        //console.log(this.props.babyData);
        
        return (      
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}                   
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Fade in={true}>
                        <div
                            style={{
                                backgroundColor: '#C8EAF9', 
                                padding: '20px',                           
                        }}>
                        <Typography >
                            <Box 
                                textAlign="center" 
                                style={{
                                    backgroundColor: '#FFE45C',
                                    padding: '8px 12px',
                                    borderRadius: '15px',
                                    fontSize: '1rem',
                                }}
                            >  
                                บันทึกการป้อนนม
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <form 
                                    onSubmit={this.handleSubmitEvent}
                                    autoComplete="on">
                                    <FormControl
                                        variant="outlined"
                                        style={{
                                            margin: 20,
                                            width: '60vw',
                                        }}
                                        
                                    >
                                    <InputLabel id="demo-simple-select-outlined-label">Name</InputLabel>
                                        <Select
                                            required
                                            // id="demo-simple-select-outlined"
                                            value={this.state.id}
                                            onChange={this.handleChange}
                                            name="id"
                                            label="id"
                                            style={{          
                                                backgroundColor: '#ffffff',
                                            }}                
                                        >
                                            {this.props.babyData.map((babyName, index) => {
                                                return (
                                                <MenuItem key={index} value={babyName.id}>{babyName.nickname}</MenuItem>
                                                )
                                            })}
                                            {/* <MenuItem value={'A'}>A</MenuItem>
                                            <MenuItem value={'B'}>B</MenuItem>
                                            <MenuItem value={'C'}>C</MenuItem> */}
                                        </Select>                                       

                                        <TextField 
                                            required
                                            onChange={this.handleChange}
                                            id="outlined-basic" 
                                            variant="outlined"
                                            name="volume"
                                            value={this.state.input.volume}
                                            type="number"                            
                                            label="ปริมาณ" 
                                            style={{
                                                backgroundColor: '#ffffff',
                                                marginTop: '20px'
                                        }}/>
                                        {/* <Alert severity="error">{this.state.errors.volume}</Alert> */}
                                        <RadioGroup 
                                            required 
                                            row 
                                            aria-label="type" 
                                            name="type"
                                            value={this.state.type}
                                            onChange={this.handleChange}                                                                                
                                            style={{ marginTop: '20px' }}>
                                            <FormControlLabel value="bl" control={<Radio color="primary" />} label="เต้าซ้าย" required />
                                            <FormControlLabel value="br" control={<Radio color="primary" />} label="เต้าขวา" />
                                        </RadioGroup>     
                                        <Button 
                                            display="inline"
                                            type="submit"
                                            onClick={this.props.isShow}                                            
                                            style={{
                                                backgroundColor: '#68CAB6',
                                                borderRadius: '5px',
                                                width: '40%',
                                                marginTop: 10,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                        }}>
                                            บันทึก
                                        </Button> 
                                        <Button 
                                            display="inline"
                                            type="reset"
                                            onClick={this.props.isShow}
                                            style={{
                                                backgroundColor: '#F89A8C',
                                                borderRadius: '5px',
                                                width: '40%',
                                                marginTop: 10,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                        }}>
                                            ยกเลิก
                                        </Button>                        
                                    </FormControl>
                                </form>
                            </Box>
                        </Typography>
                        </div>  
                    </Fade>  
                </Modal>
            </div>
        );
    }
}

export default AddMilk;