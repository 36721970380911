import React from 'react';
import axios from 'axios';
import { Button, Box,  withStyles, Accordion, AccordionSummary, AccordionDetails, Typography, } from '@material-ui/core';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import axiosIn from '../axiosIn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const MyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText('#ffffff'),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor:'#ffffff',
        },
        boxShadow: '0 1px 2px 1px rgba(0, 0, 0, .3)',
        justifyContent: 'left',
    },
}))(Button);

export default class DataButton extends React.Component {  

    state = {
        loading: true,
        catagory: [],
        post: [],
        name: '',
        des: '',
        ig: '',
        openModal: false,
    };

    openModal = (nam, des, ig) => {
        this.setState({ openModal: true });
        this.setState({ name: nam });
        this.setState({ des: des });
        this.setState({ ig: ig });
        // console.log(nam);
        // console.log(des);
        // console.log(ig);                 
    }

    closeModal =() => {
        this.setState({ openModal: false});
    }

    componentDidMount() {
        const url = 'https://babiez.dev.itrashyou.com/api/v1/category/?format=json';
        axiosIn.get("/category/").then(res => {
            this.setState({ catagory: res.data });
            // console.log(this.state.catagory);
        });
        axiosIn.get("/post/").then(res => {
            this.setState({ post: res.data });
            // console.log(this.state.post);
        });
    }

    render () {        
        
        return (
            <div style={{ width: "100%" }} >
                {this.state.catagory.map((cat, index) => {
                    return (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                // id="panel1a-header"
                            >
                                <Typography key={index} style={{ fontSize: '1.2rem', fontWeight: 700 }} >{cat.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: "block" }}>
                                <Box>
                                    <Typography key={index}>
                                        {cat.detail}
                                    </Typography>
                                </Box>
            
                                <Box>
                                    <ul style={{ width: '100%', listStyleType: 'none', margin: 0, padding : 0 }}>                
                                        {this.state.post.map((pst, index)=> {
                                            if(cat.id === pst.category)
                                                return <li ><MyButton style={{ width:'100%', marginTop: '2vh', textAlign: 'left' }} key={index} onClick={() => { this.openModal(pst.name, pst.description, pst.image)}}>
                                                    {pst.name}                                                                                         
                                                </MyButton></li>
                                        })}
                                    </ul> 
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"                  
                  open={this.state.openModal}
                  onClose={this.closeModal}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    
                  }}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={this.state.openModal}>
                    <div                         
                        style={{
                            backgroundColor: '#ffffff',
                            padding: '4vw 4vw',
                            boxShadow: '0 1px 2px 1px rgba(0, 0, 0, .3)',
                            overflow: 'auto',
                            maxHeight: '80vh',
                            // width: '80vw',
                    }}>
                      <h2 id="transition-modal-title">{this.state.name}</h2>
                      <img src={this.state.ig} style={{ width: '100%', justifySelf: 'center' }} />
                      <p id="transition-modal-description">{this.state.des}</p>
                    </div>
                  </Fade>
                </Modal>
              
            </div>
        )
    }
}
