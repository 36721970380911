import React from 'react';
import { Box, makeStyles, IconButton, ButtonGroup, Button, Tabs } from '@material-ui/core';
import { ArrowBackIos, MoreVert } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import profile_pic from './img/profile.jpg';
import PlotGraph from './component/disGraph';
import users from './component/users/users';
import axiosIn from './axiosIn';
import { Redirect , Link } from 'react-router-dom'

export default class Baby_page extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data : [],
            image: "",
            realImage: null,

            // weight: "",
            // height: "",
            profileImg : require('./photo/babiezProfile.PNG'),
            id : "",
            data : new Date(),
            num: 1,
            type: "week",
            babyId: '',
            babyData: [],
            babyDataMilk: {
                "total": "-",
                "left": "-",
                "right": "-",
                "sum_volume": "-"
            },
            babyDataSleep :{
                "sleep_avg": "-",
                "sleep_total": "-"
            }
      }
    //   this.imageHandler = this.imageHandler.bind(this);

    }

    lastDay = () => {
        // if(this.state.num >=0 && this.state.num <= 10)
            this.setState({ num: this.state.num + 1 });        
    };

    nextDay = () => {
        // if(this.state.num >=0 && this.state.num <= 10)
            this.setState({ num: this.state.num - 1 });        
    };

    weekBTW = () => {
        this.setState({ type: "week"});
    }

    monthBTW = () => {
        this.setState({ type: "month"});
    }

    yearBTW = () => {
        this.setState({ type: "year"});
    }

    // componentDidMount() {        

    //     const {match : {params}} = this.props;
    //     this.setState({ babyId: params.id });

    //     const accessToken = localStorage.getItem('accessToken');
    //     const header = {
    //       "Content-Type" : "application/json",
    //       "Accept" : "application/json", 
    //       "Authorization" : `Bearer ${accessToken}`
    //     }        
    //     axiosIn.get(`/baby/${params.id}/`  , {headers:header})
    //       .then(res => {
    //         const data = res.data; // get the data array instead of object
    //         console.log(data);
    //         this.setState({ data, loading: false });
    //         this.setState({ image : res.data.image })
    //     //   .then(res => {                            
    //     //         this.setState({ babyData: res.data });
    //     //         // console.log(this.state.babyData);                                
    //     //   })
    //     //   .then (({data : user }) =>{
    //     //       this.setState({user});
    //       })
    //       .catch(err => { // log request error and prevent access to undefined state
    //         this.setState({ loading: false, error: true });
    //         console.error(err); 
    //       })
    // }

    componentDidMount() {
        const {match : {params}} = this.props;
        this.setState({babyId:params.index})
        const accessToken = localStorage.getItem('accessToken');
        const header = {
          "Content-Type" : "application/json",
          "Accept" : "application/json", 
          "Authorization" : `Bearer ${accessToken}`
        }
        axiosIn.get(`/baby/${params.index}/`  , {headers:header})
          .then(res => {
            const data = res.data; // get the data array instead of object
            console.log(data);
            this.setState({ data, loading: false });
            this.setState({ image : res.data.image });
          })
          .catch(err => { // log request error and prevent access to undefined state
            this.setState({ loading: false, error: true });
            console.error(err); 
          })

          axiosIn.get(`/infomilk/?baby=${params.index}`  , {headers:header})
          .then(res => {
            const data = res.data; // get the data array instead of object
            this.setState({babyDataMilk: data})
            // console.log(this.state.babyDataMilk);
          })
          .catch(err => { // log request error and prevent access to undefined state
            this.setState({ loading: false, error: true });
            console.error(err); 
          })
          axiosIn.get(`/infosleep/?baby=${params.index}`  , {headers:header})
          .then(res => {
            const data = res.data; // get the data array instead of object
            this.setState({babyDataSleep: data[0]})
            console.log(this.state.babyDataSleep);
          })
          .catch(err => { // log request error and prevent access to undefined state
            this.setState({ loading: false, error: true });
            console.error(err); 
          })

    }

    // imageHandler =  (e) =>{
    //     const {profileImg} = this.state 
    //     const reader= new FileReader();
    //     reader.onload = () =>{
    //       if(reader.readyState === 2){
    //         this.setState({profileImg:reader.result})
    //       }
    //     }
    //     reader.readAsDataURL(e.target.files[0])
    //     console.log(this.state.profileImg);
    //     console.log(e.target.files[0]);
    //     this.setState({realImage:e.target.files[0],
    //         loaded:0,
    //     })



    //   }



    render() {
        const {user} = this.state;
        const {profileImg} = this.state 
        console.log(this.state);
        console.log(this.state.babyId);
        console.log(this.state.data.id);

        return (
            <div
                style={{
                    minHeight: '162.5vh',
                    display: 'block',
            }}>
                <div>         

                <img src={this.state.image} 
                    // onChange={this.imageHandler}                          
                    value={this.state.image}
                    name="image"
                    type ="image"
                    id="image"
                    alt="" 
                    id="image" 
                    style={{ backgroundColor: '#52D3D9', maxWidth:'100vw', minWidth:'100vw', minHeight:'100vw',maxHeight:'100vw'}}
                    >
                </img>       

    

                    {/* <Box 
                        style={{
                            backgroundImage: `url(${this.imageHandler})`,
                            backgroundImage: `url(${this.state.babyData.image})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: '62.5vh',
                            width: '100vw',
                    }}>
                            <Box display="flex" justifyContent="center">            
                        <Box p={2} display="flex" justifyContent="end" alignItems="flex-end" style={{ height: '100%' }}>   
                                
                        </Box>
                    </Box> */}
                        {/* <Box p={2} display="flex" justifyContent="end" alignItems="flex-end" style={{ height: '100%' }}>   
                        sddd          
                        </Box> */}

                    {/* </Box> */}
                    <Box 
                        style={{ width: '100%', marginTop: '3vh', height: '4vh' }} 
                        display="flex" 
                        justifyContent="center"
                    >
                        <ButtonGroup variant="contained" color="primary" style={{ width: '60vw', }}> 
                            <Button style={{ width: '20vw', }} onClick={this.weekBTW}>สัปดาห์</Button>
                            <Button style={{ width: '20vw', }} onClick={this.monthBTW}>เดือน</Button>
                            <Button style={{ width: '20vw', }} onClick={this.yearBTW}>ปี</Button>
                        </ButtonGroup>
                    </Box> 

                    <Box 
                        display="flex" 
                        justifyContent="space-between"
                        style={{
                            backgroundColor: '#EFEFEF',
                            borderRadius: '40px',
                            margin: '2vh 5vw',  
                            height: '5vh'                      
                        }}>            
                         <IconButton aria-label="Before" style={{ backgroundColor: '#C4C4C4', }} onClick={this.lastDay} type="submit">
                            <ArrowBackIosIcon fontSize="small"/>
                        </IconButton>    

                        <Box display="flex" justifyContent="space-around" m={1} width='100%'>
                            <Box>
                                Last    
                            </Box>  
                            <Box>
                                Next
                            </Box>                 
                        </Box>

                        <IconButton aria-label="After" style={{ backgroundColor: '#C4C4C4', }} onClick={this.nextDay}>
                            <ArrowForwardIosIcon fontSize="small"/>
                        </IconButton>
                    
                    </Box>

                    <Tabs
                        orientation="horizontal"
                        variant="scrollable"
                        display="flex"
                        alignItems="center"
                        style={{ padding: '1vh 2vw', textAlign: 'center', height: '48vh' }}
                    >                                               
                        <Box style={{ marginTop: '2vh' }}>
                            <PlotGraph graph={"milkgraph"} type={this.state.type} dkey="total" num={this.state.num} bid={this.state.babyId} name={"ปริมาณนมที่ดื่ม(มล.)"} color={"#1487b8"} />                            
                        </Box> 

                        <Box style={{ marginTop: '2vh' }}>
                            <PlotGraph graph={"recordsleepgraph"} type={this.state.type} dkey="total" num={this.state.num} bid={this.state.babyId} name={"เวลลาการนอน(นาที)"} color={"#f7c43c"} />                            
                        </Box> 

                        <Box style={{ marginTop: '2vh' }}>
                            <PlotGraph graph={"heightgraph"} type={this.state.type} dkey="avg" num={this.state.num} bid={this.state.babyId} name={"พัฒนาการส่วนสูง(ซม.)"} color={"#44bba4"} />                            
                        </Box> 

                        <Box style={{ marginTop: '2vh' }}>
                            <PlotGraph graph={"weightgraph"} type={this.state.type} dkey="avg" num={this.state.num} bid={this.state.babyId} name={"พัฒนาการน้ำหนัก(กก.)"} color={"#e66ba2"} />                            
                        </Box> 
                    </Tabs>

                    <Box                                
                        p={2}
                        style={{ backgroundColor: '#C8EAF9', height: '30vh' }}>
                        <h4 style={{ textAlign: 'center', margin: '0', backgroundColor: '#FFE45C', 
                                padding: '20px', }}>สรุปบันทึก</h4>
                        <br/>
                        <Box p={2}>
                            <Box display="flex" justifyContent="space-between">
                                <p style={{ margin: 0 }}>ดื่มนมทั้งหมด</p>
                                <p style={{ margin: 0 }}>{this.state.babyDataMilk.sum_volume} ml</p>
                            </Box>
                            <Box pl={2} display="flex" justifyContent="space-between">
                                <p style={{ margin: 0 }}>เต้าซ้าย</p>
                                <p style={{ margin: 0 }}>{this.state.babyDataMilk.left} ml</p>
                            </Box>
                            <Box pl={2} display="flex" justifyContent="space-between">
                                <p style={{ margin: 0 }}>เต้าขวา</p>
                                <p style={{ margin: 0 }}>{this.state.babyDataMilk.right} ml</p>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="space-between">
                                <p style={{ margin: 0 }}>การนอนหลับทั้งหมด</p>
                                <p style={{ margin: 0 }}>{this.state.babyDataSleep.sleep_total} นาที</p>
                            </Box>
                            <Box pl={2} display="flex" justifyContent="space-between">
                                <p style={{ margin: 0 }}>นอนเฉลี่ย</p>
                                <p style={{ margin: 0 }}>{this.state.babyDataSleep.sleep_avg} นาทีต่อวัน</p>
                            </Box>
                        </Box>
                    </Box>


                
                        <IconButton 
                                href="./"
                                style={{
                                    position: 'fixed',
                                    top: 10,
                                    left: 10,
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%',
                                }}
                        >
                                <ArrowBackIos style={{ color: 'black', marginLeft: '25%' }} />
                        </IconButton>
                        
                <Link to ={`/Baby_page/${this.state.babyId}`}>
                    
                    <IconButton 
                        style={{
                            position: 'fixed',
                            top: 10,
                            right: 10,
                            backgroundColor: '#ffffff',
                            borderRadius: '50%',
                        }}  
                    >
                        <MoreVert style={{ color: 'black' }} />
                    </IconButton>
                    
                </Link>
    
                </div>
            </div>
        );
    }
}
