import React from 'react';
import axios from 'axios';
import axiosIn from '../axiosIn';
import { Container, Typography, Box, Divider } from '@material-ui/core';

class Activity extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            date: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(new Date()),
            day: new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(new Date()),
            month: new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(new Date()),
            year: new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(new Date()),
            num: 0,
            type: 'w',
            actData: [],
        };
    };

    lastDay = () => {
        // if(this.state.num >=0 && this.state.num <= 10)
            this.setState({ num: this.state.num + 1 });
                    
    };

    nextDay = () => {
        // if(this.state.num >=0 && this.state.num <= 10)
            this.setState({ num: this.state.num - 1 });        
    };    

    componentDidMount() {
        // console.log("Pass")
        const accessToken = localStorage.getItem('accessToken');
        const header = {
          "Content-Type" : "application/json",
          "Accept" : "application/json", 
          "Authorization" : `Bearer ${accessToken}`
        }
        axiosIn.get('/activity/' , {headers:header}).then(res => {            
            this.setState({ actData : res.data });
            console.log(this.state.actData);
        }).catch(err => { 
            this.setState({ loading: false, error: true });
            console.error(err); 
          })

    }

    componentDidUpdate() {
        // console.log("num:" + this.state.num + " type:" + this.state.type);
        // console.log("date:" + new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.state.date));
        // console.log("date:" + this.state.date);    
    };

    timeTran = (props) => {
        let time = this.props.t;
        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(time);
    };   

    render() {
        return (
            <Container 
                width="100vw"
                style={{ 
                    backgroundColor: '#C8EAF9', 
                    padding: 0,
                    minHeight: '48vh', //Test fix height 
                    maxHeight: '100vh',
            }}>
                <Box display="flex" justifyContent="center" p={1}>
                    <Typography variant='h6'>
                        กิจกรรมที่ผ่านมาล่าสุด
                    </Typography>       
                </Box>
                
                <Container 
                    style={{
                        width: '100%',
                        padding: 0,
                        // marginTop: '1vh',
                    }}>
                        {this.state.actData.map((actDetail, index) => {         
                            var time = actDetail.timestamp;                                  
                            if(actDetail.type === "Milk") {   
                                if(actDetail.types === "br"){
                                    return (
                                    <Box 
                                        key={index}                                        
                                        style={{ 
                                            backgroundColor: ' #F6D7E5', 
                                            maxWidth: '100vw',
                                            fontSize: '16px',
                                        }}>
                                        <Box p={1}>
                                            <p style={{ margin: 0, fontSize: '1.1rem', fontWeight: '500' }}>ให้นมเต้าขวา {actDetail.volume} ml</p>
                                            <Box display="flex" justifyContent="space-between">
                                                <p style={{ margin: 0, }}>{actDetail.baby}</p>
                                                <p style={{ margin: 0, }}>{this.timeTran(time)}</p>
                                            </Box>
                                        </Box>                                                                   
                                        <Divider />
                                    </Box>
                                    );
                                }
                                else if(actDetail.types === "bl"){
                                    return (
                                    <Box 
                                        key={index}                
                                        style={{ 
                                            backgroundColor: ' #F6D7E5', 
                                            maxWidth: '100vw',
                                            fontSize: '16px',
                                        }}>
                                        <Box p={1}>
                                            <p style={{ margin: 0, fontSize: '1.1rem', fontWeight: '500' }}>ให้นมเต้าซ้าย {actDetail.volume} ml</p>
                                            <Box display="flex" justifyContent="space-between">
                                                <p style={{ margin: 0, }}>{actDetail.baby}</p>
                                                <p style={{ margin: 0, }}>{this.timeTran(time)}</p>
                                            </Box>                                                                                                                         
                                        </Box>
                                        <Divider />
                                    </Box>
                                    )     
                                }
                            }                                                         
                            else if(actDetail.type === "Excretion")
                                return (
                                    <Box 
                                        key={index}                                        
                                        style={{ 
                                            backgroundColor: ' #F6D7E5', 
                                            maxWidth: '100vw',
                                            fontSize: '16px', 
                                        }}> 
                                        <Box p={1}>                                       
                                            <p style={{ margin: 0, fontSize: '1.1rem', fontWeight: '500' }}>ขับถ่าย {actDetail.description}</p>
                                            <Box display="flex" justifyContent="space-between">
                                                <p style={{ margin: 0, }}>{actDetail.baby}</p>
                                                <p style={{ margin: 0, }}>{this.timeTran(time)}</p>
                                            </Box>
                                        </Box>
                                        <Divider />
                                    </Box>
                                )
                            else if(actDetail.type === "RecordSleep")
                                return (
                                    <Box 
                                        key={index}
                                        style={{ 
                                            backgroundColor: ' #F6D7E5', 
                                            maxWidth: '100vw',
                                            fontSize: '16px', 
                                        }}>   
                                        <Box p={1}>                                            
                                            <p style={{ margin: 0, fontSize: '1.1rem', fontWeight: '500' }}>นอน {actDetail.time} นาที</p>
                                            <Box display="flex" justifyContent="space-between">
                                                <p style={{ margin: 0, }}>{actDetail.baby}</p>
                                                <p style={{ margin: 0, }}>{this.timeTran(time)}</p>
                                            </Box> 
                                        </Box>
                                        <Divider />
                                    </Box>
                                )
                            else if(actDetail.type === "Growth")
                                return (
                                    <Box 
                                        key={index}                                
                                        style={{ 
                                            backgroundColor: ' #F6D7E5', 
                                            maxWidth: '100vw',
                                            fontSize: '16px', 
                                        }}>
                                        <Box p={1}>
                                            <p style={{ margin: 0, fontSize: '1.1rem', fontWeight: '500' }}>บันทึการเติบโต {actDetail.weight} กก. {actDetail.height} ซม.</p>
                                            <Box display="flex" justifyContent="space-between">
                                                <p style={{ margin: 0, }}>{actDetail.baby}</p>
                                                <p style={{ margin: 0, }}>{this.timeTran(time)}</p>
                                            </Box> 
                                        </Box> 
                                        <Divider />
                                    </Box>
                                )
                        })}
                    
                </Container>
            </Container>
        )
    }
    
}

export default Activity;