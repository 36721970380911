import React, { useEffect, Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { Grid, Typography, Box, Avatar, RadioGroup, Button, } from '@material-ui/core';
import { BottomNavigation, BottomNavigationAction, Backdrop } from '@material-ui/core';
import { Fade, Modal, makeStyles, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import { FormControlLabel, Radio } from '@material-ui/core';
import profile_pic from './img/profile.jpg';
import BabyCard from './component/babycard';
import Activity from './component/activity';
import AddMilk from './component/addMilk';
import AddExcretion from './component/addExcretion';
import AddSleep from './component/addSleep';
import AddGrowth from './component/addGrowth';
import axiosIn from './axiosIn';


export class User_Home extends React.Component {  

    constructor(props) {
        super(props);
        this.state = { 
            modalAddMilk: false,
            modalAddExcretion: false,
            modalAddsleep: false,
            modalAddGrowth: false,
            userData: [],
            feces: [],
            image: "",
            realImage: null,
            profileImg: require("./photo/babiezProfile.PNG"),
            notimilk: {
                "timeleft": "-",
                "baby": "-"
            }
            
            
        };
        // this.useAddMilk = this.useAddMilk.bind(this);
        // this.resetAddMilk = this.resetAddMilk.bind(this);
    }


    useAddMilk = () => { this.setState({ modalAddMilk: true }) }
    resetAddMilk = () => { this.setState({ modalAddMilk: false }) }

    useAddExcretion = () => { this.setState({ modalAddExcretion: true }) }
    resetAddExcretion = () => { this.setState({ modalAddExcretion: false }) }

    useAddSleep = () => { this.setState({ modalAddsleep: true }) }
    resetAddSleep = () => { this.setState({ modalAddsleep: false }) }

    useAddGrowth = () => { this.setState({ modalAddGrowth: true }) }
    resetAddGrowth = () => { this.setState({ modalAddGrowth: false }) }

    getData = () => {
        const accessToken = localStorage.getItem('accessToken');
        const header = {
            "Content-Type" : "application/json",
            "Accept" : "application/json", 
            "Authorization" : `Bearer ${accessToken}`
        }
        axiosIn.get(`/baby/`  , {headers:header})
          .then(res => {  
                this.setState({ userData: res.data });

                // console.log(res.data);                                
          });

        axiosIn.get(`/auth/edit_account/`  , {headers:header})
        .then(res => {  
            this.setState({ profileImg: res.data[0].image });
            // console.log(res.data);                                
        });
        
        axiosIn.get(`/feces/`  , {headers:header})
          .then(res => {                            
                this.setState({ feces: res.data });
                // console.log(this.state.feces);                                
          });
        axiosIn.get(`/notimilk/`  , {headers:header})
          .then(res => {                            
                this.setState({ notimilk: res.data });
                // console.log(this.state.feces);                                
          });
    }

    componentDidMount() {
        this.getData();
            
    }

    componentDidUpdate() {

    }

    render() {
        //console.log("home render");
        if (this.state.isSuccess) {
            // return <Redirect to={{ pathname: "/User_Home" }} />;
            return window.location.href = '/User_Home';
        }
        return (
            <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
                <Grid container style={{ padding: '10px 0px', justifyContent: 'space-between' }}>
                    <Grid item xs={9} 
                        // style={{ backgroundColor: '#F0F0F0', borderRadius: '0px 20px 20px 0px' }}
                        >
                        <Typography>
                            <Box fontSize='1rem' p={1}>
                                อีก {this.state.notimilk.timeleft} นาที ถึงเวลาให้นม {this.state.notimilk.baby}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: '5vw'}}>
                        <a href="/editProfile">
                            <Avatar alt="Profile" 
                            src={this.state.profileImg}
                            value={this.state.profileImg}
                            name="image"
                            type="image"
                            id="image"
                            alt=""
                            id="image"
                            
                            />
                        </a>
                    </Grid>     
                </Grid>
                
                <BabyCard />

                <Activity />                       

                {this.state.modalAddMilk ? <AddMilk isShow={this.resetAddMilk} babyData={this.state.userData} /> : null}
                {this.state.modalAddExcretion ? <AddExcretion isShow={this.resetAddExcretion} babyData={this.state.userData} feces={this.state.feces} /> : null}
                {this.state.modalAddsleep ? <AddSleep isShow={this.resetAddSleep} babyData={this.state.userData} /> : null}
                {this.state.modalAddGrowth ? <AddGrowth isShow={this.resetAddGrowth} babyData={this.state.userData} s/> : null}

                <BottomNavigation 
                    style={{ 
                        position: 'absolute', 
                        bottom: 0, 
                        width: '100vw', 
                        backgroundColor: '#FFEE99',                 
                    }}
                    showLabels
                >
                    <BottomNavigationAction 

                        label="บันทึกป้อนนม" 
                        style={{ color: 'black' }}
                        type="button"
                        onClick = {() => { 
                            this.useAddMilk();
                        }}    
                    />
                    <BottomNavigationAction 
                        label="บันทึกการขับถ่าย" 
                        style={{ color: 'black' }}   
                        type="button"
                        onClick = {() => { 
                            this.useAddExcretion();
                        }}                     
                    />
                    <BottomNavigationAction 
                        label="บันทึกการนอน"                    
                        style={{ color: 'black' }}
                        type="button"
                        onClick = {() => { 
                            this.useAddSleep();
                        }}            
                    />
                    <BottomNavigationAction 
                        label="บันทึกพัฒนาการ" 
                        style={{ color: 'black' }}
                        type="button"
                        onClick = {() => { 
                            this.useAddGrowth();
                        }}
                    />
                    <BottomNavigationAction 
                        label="ข้อมูลเพิ่มเติม" 
                        style={{ color: 'black' }}
                        href = "./"
                    />
                </BottomNavigation>
                
            </div>
        );
    }
}

export default User_Home;
