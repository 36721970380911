import React , {Component} from 'react';
import {Route , Link} from 'react-router-dom'
import './App.css';
import login from './login';
import regis from './regis';
import editProfile from './editProfile';
import Home from './Home';
import User_Home from './User_Home';
import test from './testtt';
import addBaby from './addBaby';
import editBaby from './editBaby';
import Baby_page from './Baby_page'




function App(){
    return(
        <div className="App" >
            <Route exact path="/login" component={login} />
            <Route exact path="/regis" component={regis} />
            <Route exact path="/editProfile" component={editProfile} />
            <Route exact path="/" component={Home} />
            <Route exact path="/User_Home" component={User_Home} />
            {/* <Route exact path="/User_Home/:id" component={Baby_page} />  */}
            {/* Baby_page for real */}
            <Route exact path="/test" component={test} />
            <Route exact path="/addBaby" component={addBaby} />
            <Route exact path="/User_Home/:index" component={Baby_page} />
            <Route exact path="/Baby_Page/:index" component={editBaby} />
            {/* <Route exact path="/editBaby/:index" component={editBaby} /> */}
            {/* <Route exact path="/Baby_page" component={Baby_page} />
            <Route exact path="/Baby_page/:index" component={Baby_page} /> */}


        </div>
    );
}

export default App;

