import React , {Component} from 'react';
import axios from './axiosIn'
import ReactDOM from 'react-dom';
import regis from './regis';
import Users from './component/users/users';


export default class testtt extends Component{
    constructor(props) {
        super(props);
        this.state = {
            Users: []
        };
    }
    getUsersData() {
        const header = {
            "Content-Type" : "application/json"
          }
        axios
            .get(`/users`, {headers:header})
            .then(res => {
                const data = res.data
                console.log(data)
                const users = data.map(u =>
                    <div>
                    <p>{u.input.firstname}</p>
                    <p>{u.account.lastname}</p>
                    <p>{u.email}</p>
                    </div>
                    )

                    this.setState({
                        users
                    })

            })
            .catch((error) => {
                console.log(error)
            })

    }
    componentDidMount(){
        this.getUsersData()
    }
     
      
      render() {
        return(
            <div>
                {this.state.users}
                {this.state.users.firstname}
                sss
            </div>

        );
      }
     
      
      
     }
