import React from 'react';
import { Button, Typography, Box, InputLabel, Fade, Modal, Backdrop } from '@material-ui/core';
import { Select, MenuItem, TextField, FormControl } from '@material-ui/core';
import axiosIn from '../axiosIn';

class AddSleep extends React.Component {  

    constructor(props) {
        super(props);
        this.state = {
            baby: '',
            time: '', 
        };

        this.handleSubmitEvent = this.handleSubmitEvent.bind(this);
    }

    handleChange = (e) => {
        let name = e.target.name;
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [name]: value });
           
    }


    handleSubmitEvent = () => {
        // console.log("summit work");        
        const accessToken = localStorage.getItem('accessToken');
        const header = {
          "Content-Type" : "application/json",
          "Accept" : "application/json", 
          "Authorization" : `Bearer ${accessToken}`
        }

        let data = {
            "baby" : this.state.id,
            "time" : this.state.time,
        }        
        // console.log(data);
        
        axiosIn.post(`recordsleep/`,  data , {headers:header})
        .then(res => {
            // console.log(res);
            // console.log(res.data);
            // alert("uploade");
            if (res.status === 201) {
                this.setState({ isSuccess: true }); // after signing up, set the state to true. This will trigger a re-render
            }
        })
        .catch(error => {
            console.log(error);
            // alert(error);
        })
        
        // this.setState({ closeModal: this.props.isShow });
    }


    componentWillUnmount() {
        this.handleSubmitEvent();
    }

    render() {    

        return (      
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}                    
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Fade in={true}>
                        <div
                            style={{
                                backgroundColor: '#C8EAF9', 
                                padding: '20px',                           
                        }}>
                        <Typography >
                        <Box 
                            textAlign="center" 
                            style={{
                                backgroundColor: '#FFE45C',
                                padding: '8px 12px',
                                borderRadius: '15px',
                                fontSize: '1rem',
                            }}
                        >  
                            บันทึกเวลานอน
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <FormControl
                                variant="outlined"
                                style={{
                                    margin: 20,
                                    width: '60vw',
                                }}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">Name</InputLabel>
                                    <Select
                                        required
                                        labelId="name"
                                        id="demo-simple-select-outlined"
                                        value={this.state.id}
                                        onChange={this.handleChange}
                                        name="id"
                                        label="ชื่อ" 
                                        style={{                                            
                                            backgroundColor: '#ffffff',
                                        }}                
                                    >
                                        {this.props.babyData.map((babyName, index) => {
                                            return (
                                            <MenuItem key={index} value={babyName.id}>{babyName.nickname}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <TextField 
                                        required 
                                        id="outlined-basic" 
                                        variant="outlined"
                                        value={this.state.time}
                                        name="time"
                                        onChange={this.handleChange}
                                        type="number"
                                        label="เวลานอน" 
                                        style={{
                                            backgroundColor: '#ffffff',
                                            marginTop: '20px'
                                    }}/>                                        
                                    <Button 
                                        display="inline"
                                        type="submit"
                                        onClick={this.props.isShow}
                                        style={{
                                            backgroundColor: '#68CAB6',
                                            borderRadius: '5px',
                                            width: '40%',
                                            marginTop: 10,
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                    }}>
                                        บันทึก
                                    </Button> 
                                    <Button 
                                        display="inline"
                                        onClick={this.props.isShow}
                                        style={{
                                            backgroundColor: '#F89A8C',
                                            borderRadius: '5px',
                                            width: '40%',
                                            marginTop: 10,
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                    }}>
                                        ยกเลิก
                                    </Button>                    
                                </FormControl>
                                </Box>
                            </Typography>
                        </div>  
                    </Fade>  
                </Modal>
            </div>
        );
    }
}

export default AddSleep;