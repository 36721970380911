import React from 'react';
import DataSelect from './home_select';
import logo from '../img/icon_Babiez_edit.png';
import { Grid, Box, AppBar, withStyles, Button } from '@material-ui/core';
import { height } from '@material-ui/system';

export default class showData extends React.Component {
    render () {
        const MyButton = withStyles((theme) => ({
            root: {
                //color: theme.palette.getContrastText('#ffffff'),
                backgroundColor: '#ffffff',
                '&:hover': {
                    backgroundColor:'#ffffff',
                },
                boxShadow: '0 2px 4px 1px rgba(0, 0, 0, .3)',
            },
        }))(Button);

        return (
            <div>
                <div style={{ backgroundColor: '#ffee99', minHeight: '100vh' }}>
                    <AppBar position="static" className="TopNav"  elevation={0} >
                    <div style={{ width: '100vw', }}>                    
                            <Box display="flex" justifyContent="space-between" >
                                <Box p={1}>
                                    <MyButton href="./user_home">Back</MyButton>                  
                                </Box>
                                
                                <Box p={1} alignSelf="center">
                                    
                                </Box>
                            </Box>                   
                        </div>
                    </AppBar>
                    
                    <Box m={1} p={1}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Box display="flex" justifyContent="center">
                                <img src={logo} alt="logo" style={{ width: '50vw', height: '50vw' }} />
                            </Box>
                            <DataSelect />
                            {/* <TestModal />               */}
                        </Grid>
                    </Box>
                </div>
                {/* <DataSelect /> */}
            </div>
        )
    }
}